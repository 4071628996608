<template>
    <div class="ticketInfo">
        <van-nav-bar
  title="订票信息"
  left-arrow
  @click-left="$router.back()"
/>
  <van-form @submit="onSubmit">
      <van-field name="radio" label="称呼">
  <template #input>
    <van-radio-group v-model="obj.radio" direction="horizontal">
      <van-radio name="女">女</van-radio>
      <van-radio name="男">男</van-radio>
    </van-radio-group>
  </template>
</van-field>
  <van-field
    v-model="obj.username"
    name="姓名"
    label="姓名"
    placeholder="请输入姓名以方便称呼"
    :rules="[{ required: true, message: '' }]"
  />
  <van-field
    v-model="obj.password"
    type="password"
    name="手机号码"
    label="手机号码"
    placeholder="请输入手机号以便联系"
    :rules="[{ required: true, message: '' }]"
  />
  <div style="margin: 16px;">
    <van-button round block type="info" native-type="submit">确定</van-button>
  </div>
  </van-form>
  <div class="tips">请认真核对信息并确认无误</div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
              obj:{
                 username:'',
                password:'',
                radio:'女'
              }
               
            }
        },
        methods:{
            onSubmit(){
              
                if(this.obj.username!=''&&this.obj.password!=''&&this.obj.radio!=''){
                   console.log(this.obj);
                sessionStorage.setItem('info',JSON.stringify(this.obj) )
                this.$router.push({
                  path:'/Ticket'
                })
                }
            },
            onClickLeft(){

            }
        }
    }
</script>

<style lang="scss" scoped>
.ticketInfo{
    width:100%;
    height:100%;
    .tips{
        color:red;
        text-align: center;
    }
}
</style>